$(function() {
  // 以下為Swiper設定
 
   // 共用Auto 
   var auplayFun = {
     delay: 3500,
     //stopOnLastSlide: false,
     disableOnInteraction: false,
   }
 
   // 首頁Banner
   if($('.swiper-bannerIn').length > 0) {
     var swiper_bannerIn = new Swiper(".swiper-bannerIn .swiper-container", {
      // direction: "horizontal",
      loop: true,
      centeredSlides : true,
      slidesPerView:'auto',
      navigation: {
        nextEl: '.swiper-bannerIn .swiper-button-next',
        prevEl: '.swiper-bannerIn .swiper-button-prev',
      },
      grabCursor: true,
      speed: 1000,
      pagination: '.swiper-bannerIn .swiper-pagination',
      paginationClickable: true,
      // parallax: true,
      // effect: "slide",
      reverseDirection: true,

      // autoplay: {
      //   reverseDirection: true,
      // },
      autoplay: false, //auplayFun
      // mousewheelControl: 1,
     });
   }

  // 
  $('.list-group-mobile-btn').on('click',function(){
    $('.link-horizontal-wrap').toggleClass('open');
    return false;
  })
 
   // 以下為Gotop
  $(".float-link .link-btn.top, .float-link .link-btn.mobile-top").on('click',function () {
    $("html,body").animate({ "scrollTop": "0" })
  });
 
   $(window).on("load resize scroll", function (e) {
     var scroll = $(this).scrollTop();
     var wdh = $(window).height();
     if (scroll > 50) {
         $(".float-link").addClass("active");
     } else {
         $(".float-link").removeClass("active");
     }
   });

   // BS navbar-collapse show / hidden
   $('.navbar-toggler').on('click',function(){
    $('.navbar-overlay, .navbar-toggler.fixed').addClass('active')
  })
 
  $('#js-navbarToggle-fixed').on('click', function () {
    $('#navbar-content').removeClass('show');
    $('.navbar-overlay, .navbar-toggler.fixed').removeClass('active')
  });
 

  //// ---- jQ Date & TimePicker function area ---- ////
  $('.js-regisCheck').on('click',function(){
    $('.js-regisform').animate({height: "toggle", opacity: "toggle"}, "slow");
  });

  // 先顯示當日期 / Weeks
  let getDate = new Date();
  let strgetDate = getDate.getFullYear() + "." + (getDate.getMonth()+1) + "." + getDate.getDate();
  let weekday = ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"]

  $('.input-group-calendar.disabled #alternate_date').html(strgetDate);
  $('.input-group-calendar.disabled #alternate_week').html(weekday[getDate.getDay()]);

  // 再選擇選擇所屬日期
  $("#datepicker").datepicker({
    currentText: "Now",
    dateFormat: "yy.mm.dd",
    minDate: new Date(),
    dayNamesMin:  [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
    dayNames: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"],
    altField: "#alternate",
    altFormat: "yy.mm.dd,DD",

    // 工程師撰寫
    onSelect: function(){
      let temp = $('#alternate').val().split(',');
      let strDate = '';
      let strWeek = '';
      if (temp.length > 1) {
        strDate = temp[0];
        strWeek = temp[1];
      }

      $('#alternate_date').html(strDate);
      $('#alternate_week').html(strWeek);
      $('#datepicker').closest('.input-group-calendar').removeClass('disabled')
    }
  });

  // 先顯示當日時間
  function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }
  
  $(".input-group-calendar.disabled .timepicker__result-time").html(formatAMPM(new Date));

  // 再選擇所屬日期的對應時間
  $(".timepicker__result").on('click',function(e) {
    $(".timepicker__times").addClass("open");
  });
  
  $(".timepicker__time-minute").on("click", function(e) {
    e.stopPropagation();
    
    if($(this).hasClass('disabled')){
      Swal.fire({
        title: '時間已額滿!',
        text: "請重新選擇時間",
        icon: 'warning',
        confirmButtonText: '關閉'
      });

      $(".timepicker__times").removeClass("open");
      return
    }

    $('.timepicker__time-minute').removeClass('cur')
    $(this).addClass('cur');

    var minute = $(this).html();
    $(".timepicker__result-time").html(minute);
    $(".timepicker__times").removeClass("open");
    $('.timepicker__result').closest('.input-group-calendar').removeClass('disabled')
  });

  // SweetAlert
  if($('.sweetRegist').length > 0) {
    document.querySelector('.sweetRegist').onclick = function(){
      Swal.fire({
          title: '已完成掛號!',
          text: "網路掛號成功後，看診當日依掛號時間至侯診區等候看診",
          icon: 'success',
          confirmButtonText: '返回首頁'
      }).then((result) => {
          window.location.href = "index.html";
      });
    };
  }
  
  if($('.sweetCancel').length > 0) {
    document.querySelector('.sweetCancel').onclick = function(){
      Swal.fire({
          title: '取消掛號成功!',
          text: "如欲重新預約，請至預約頁面填寫資料即可",
          icon: 'success',
          confirmButtonText: '返回首頁'
      }).then((result) => {
          window.location.href = "index.html";
      });
    };
  }

  // --- End ---- //

 });
 
 function resize() {
   var winHeight = $(window).height();
   $(".modal-autoheight .modal-body").css({
     width: "auto",
     height: (winHeight - 200) + "px"
   });
 }
// 文字點點點 checkEllipsis('.jsEllipsis',50);
 
 // 取得頁面寬度
 function viewport() {
   var e = window,
       a = 'inner';
   if (!('innerWidth' in window)) {
       a = 'client';
       e = document.documentElement || document.body;
   }
   return { width: e[a + 'Width'], height: e[a + 'Height'] };
 }
 
 // 文字點點點
 function checkEllipsis(name,len){
   $(name).each(function(i){
     if($(this).text().length>len){
         $(this).attr("title",$(this).text());
         let text=$(this).text().substring(0,len-1)+"...";
         $(this).text(text);
     }
   });
 }
 